import Map from '@/assets/img/for-every-child/wherewework_map1@2x.png';
import Image from '@/components/Image';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import React from 'react';
import styled from 'styled-components';

const Flex = styled.div`
  padding-top: 64px;
  display: flex;
  flex-wrap: wrap;
  margin: 0px -16px;
  align-items: stretch;

  .col {
    width: 50%;
    margin-bottom: 64px;

    &:last-child {
      margin-bottom: 0;
    }

    dt {
      padding-bottom: 64px;

      strong {
        display: inline-block;
        line-height: 44px;
        height: 56px;
        border-radius: 56px;
        border: 4px solid transparent;
        padding: 0 24px;
        font-size: 20px;

        &.c1 {
          border-color: #2589c9;
          color: #2589c9;
        }
        &.c2 {
          border-color: #1cabe2;
          color: #1cabe2;
        }
        &.c3 {
          border-color: #72c9eb;
          color: #72c9eb;
        }
        &.c4 {
          border-color: #e2f0f6;
          color: #72c9eb;
        }
        &.c5 {
          border-color: #72c9eb;
          color: #72c9eb;
        }
        &.c6 {
          border-color: #3162af;
          color: #3162af;
        }
        &.c6 {
          border-color: #374ea2;
          color: #374ea2;
        }

        .cnt {
          font-size: 28px;
        }
      }
    }
  }

  ${breakpoint(`tablet`)} {
    padding-top: 48px;

    .col {
      dt {
        padding-bottom: 24px;

        strong {
          font-size: 16px;

          .cnt {
            font-size: 22px;
          }
        }
      }
    }
  }

  ${breakpoint(`mobile`)} {
    .col {
      width: 100%;
      margin-bottom: 72px;
      padding: 0px 10px;
    }
  }
`;

const Content = styled.div`
  header {
    padding: 64px 0;
    border-bottom: 1px solid #b7b8ba;

    .haeder-dec {
      margin-top: 32px;
    }
  }

  ${breakpoint(`mobile`)} {
    header {
      padding: 48px 0;

      .haeder-dec {
        margin-top: 24px;
      }
    }
  }
`;

const WorkTab1: React.FC = () => (
  <Content>
    <Image pcSrc={Map} mobileSrc={Map} />
    <header>
      <h2>
        <Tit size="s2" color="sky">
          유니세프 기부를 통해 도움을 받는 158개 유니세프 국가 사무소
        </Tit>
      </h2>
      <p className="haeder-dec">
        도움을 받는 158개 유니세프 국가사무소는 전 세계 어린이의
        보건·영양·식수와 위생·교육·보호·긴급구호 사업을 펼칩니다.
      </p>
    </header>

    <Flex>
      <dl className="col">
        <dt>
          <h3>
            <strong className="c1">
              동아시아/태평양 <span className="cnt">27</span>개
            </strong>
          </h3>
        </dt>
        <dd>
          <p className="dec">
            나우루, 니우에, 동티모르, 라오스, 마셜제도, 말레이시아, 몽골,
            미얀마, 미크로네시아, 바누아투, 베트남, 북한, 사모아, 솔로몬제도,
            인도네시아, 중국, 캄보디아, 쿡제도, 키리바시, 태국, 토켈라우제도,
            통가, 투발루, 파푸아뉴기니, 팔라우, 피지, 필리핀
          </p>
        </dd>
      </dl>
      <dl className="col">
        <dt>
          <h3>
            <strong className="c2">
              남아시아 <span className="cnt">8</span>개
            </strong>
          </h3>
        </dt>
        <dd>
          <p className="dec">
            네팔, 몰디브, 방글라데시, 부탄, 스리랑카, 아프가니스탄, 인도,
            파키스탄
          </p>
        </dd>
      </dl>
      <dl className="col">
        <dt>
          <h3>
            <strong className="c3">
              중동/북아프리카 <span className="cnt">20</span>개
            </strong>
          </h3>
        </dt>
        <dd>
          <p className="dec">
            리비아, 바레인, 레바논, 모로코, 사우디아라비아, 수단, 시리아,
            아랍에미리트, 알제리,예멘, 오만, 요르단, 이라크, 이란, 이집트,
            지부티, 쿠웨이트, 팔레스타인, 카타르, 튀니지
          </p>
        </dd>
      </dl>
      <dl className="col">
        <dt>
          <h3>
            <strong className="c4">
              동남아프리카 <span className="cnt">21</span>개
            </strong>
          </h3>
        </dt>
        <dd>
          <p className="dec">
            나미비아, 남수단공화국, 남아프리카공화국, 레소토, 르완다,
            마다가스카르, 말라위, 모잠비크, 보츠와나, 부룬디, 소말리아,
            스와질란드, 앙골라, 에리트레아, 에티오피아, 우간다, 잠비아,
            짐바브웨, 케냐, 코모로, 탄자니아
          </p>
        </dd>
      </dl>
      <dl className="col">
        <dt>
          <h3>
            <strong className="c5">
              서부/중앙아프리카 <span className="cnt">24</span>개
            </strong>
          </h3>
        </dt>
        <dd>
          <p className="dec">
            가나, 가봉, 감비아, 기니, 기니비사우, 나이지리아, 니제르,
            라이베리아, 말리, 모리타니아, 베냉, 부르키나파소, 상투메프린시페,
            세네갈, 시에라리온, 적도기니, 중앙아프리카공화국, 차드, 카메룬,
            카보베르데, 코트디부아르, 콩고, 콩고민주공화국, 토고
          </p>
        </dd>
      </dl>
      <dl className="col">
        <dt>
          <h3>
            <strong className="c1">
              유럽/중앙아시아 <span className="cnt">22</span>개
            </strong>
          </h3>
        </dt>
        <dd>
          <p className="dec">
            그리스, 루마니아, 마케도니아, 몬테네그로, 몰도바, 벨라루스,
            보스니아-헤르체코비나, 불가리아, 세르비아, 아르메니아, 아제르바이잔,
            알바니아, 우즈베키스탄, 우크라이나, 조지아, 카자흐스탄, 코소보,
            크로아티아, 키르기스스탄, 타지키스탄, 투르크메니스탄, 튀르키예
          </p>
        </dd>
      </dl>
      <dl className="col">
        <dt>
          <h3>
            <strong className="c6">
              라틴아메리카/카리브해연안 <span className="cnt">36</span>개
            </strong>
          </h3>
        </dt>
        <dd>
          <p className="dec">
            가이아나, 과테말라, 그레나다, 니카라과, 도미니카, 도미니카공화국,
            멕시코, 몬세라트섬, 바베이도스, 버진 아일랜드, 베네수엘라, 벨리즈,
            볼리비아, 브라질, 세인트루시아, 세인트빈센트그레나딘, 세인트키츠
            네비스, 수리남, 아르헨티나, 아이티, 앤티가바부다, 앵귈라, 에콰도르,
            엘살바도르, 온두라스, 우루과이, 자메이카, 칠레, 코스타리카,
            콜롬비아, 쿠바, 터크스케이커스제도, 트리니다드토바고, 파나마,
            파라과이, 페루
          </p>
        </dd>
      </dl>
    </Flex>
  </Content>
);

export default WorkTab1;
