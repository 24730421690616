import Blit1 from '@/assets/img/for-every-child/wherewework_blit1@2x.png';
import Blit2 from '@/assets/img/for-every-child/wherewework_blit2@2x.png';
import Blit3 from '@/assets/img/for-every-child/wherewework_blit3@2x.png';
import Blit4 from '@/assets/img/for-every-child/wherewework_blit4@2x.png';
import Map from '@/assets/img/for-every-child/wherewework_map2@2x.png';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import React from 'react';
import styled from 'styled-components';

const UlFlex = styled.ul`
  padding-top: 76px;

  > li {
    width: 100%;
    margin-bottom: 53px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .col {
    width: 100%;

    dt,
    dd {
      display: inline-block;
      line-height: 48px;
    }
    dt {
      margin-right: 16px;
      strong {
        display: inline-block;
        padding-left: 44px;
        font-size: 20px;
        position: relative;

        &::before {
          content: '';
          display: block;
          width: 30px;
          height: 30px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
        }

        &.c1 {
          color: #1cabe2;
          &::before {
            background-image: url(${Blit1});
          }
        }
        &.c2 {
          color: #2589c9;
          &::before {
            background-image: url(${Blit2});
          }
        }
        &.c3 {
          color: #72c9eb;
          &::before {
            background-image: url(${Blit3});
          }
        }
        &.c4 {
          color: #3162af;
          &::before {
            background-image: url(${Blit4});
          }
        }
      }
    }
  }

  ${breakpoint(`tablet`)} {
    padding-top: 48px;

    .col {
      dt,
      dd {
        width: 100%;
        line-height: 1.6;

        strong {
          font-size: 16px;
          padding-left: 40px;
          &::before {
            width: 26px;
            height: 26px;
          }
        }
      }
      dd {
        padding-left: 40px;
      }
    }
  }
`;

const Content = styled.div`
  header {
    padding: 64px 0;
    border-bottom: 1px solid #b7b8ba;

    .haeder-dec {
      margin-top: 32px;
    }
  }

  ${breakpoint(`mobile`)} {
    header {
      padding: 48px 0;

      .haeder-dec {
        margin-top: 24px;
      }
    }
  }
`;

const WorkTab2: React.FC = () => (
  <Content>
    <Image pcSrc={Map} mobileSrc={Map} />
    <header>
      <h2>
        <Tit size="s2" color="sky">
          유니세프 본부
        </Tit>
      </h2>
      <p className="haeder-dec">
        유니세프 본부는 국가위원회와 국가사무소의 활동을 함께 기획하며, 추진
        과정 및 성과를 면밀히 모니터링하고 평가합니다. 또한 7개의 지역사무소를
        두어 각 국가사무소를 지원합니다. 덴마크 코펜하겐에 위치한 유니세프
        물류센터는 전 세계에 필요한 구호 물품을 신속하게 지원하며, 이탈리에
        피렌체에 있는 연구 센터에서는 전 세계 어린이의 상황과 정책을 조사합니다.
      </p>
    </header>

    <UlFlex className="flex">
      <li>
        <LinkSafe target="_blank" to="https://www.unicef.org/">
          <dl className="col">
            <dt>
              <h3>
                <strong className="c1">유니세프 본부</strong>
              </h3>
            </dt>

            <dd>미국 뉴욕</dd>
          </dl>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="https://www.unicef.org/">
          <dl className="col">
            <dt>
              <h3>
                <strong className="c1">유니세프 유럽 본부</strong>
              </h3>
            </dt>
            <dd>스위스 제네바</dd>
          </dl>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="https://www.unicef.org/supply/">
          <dl className="col">
            <dt>
              <h3>
                <strong className="c2">구호물품 물류센터</strong>
              </h3>
            </dt>
            <dd>
              덴마크 코펜하겐, 아랍에미리트 두바이, 이탈리아 브린디시, 파나마,
              중국 광저우
            </dd>
          </dl>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="https://www.unicef-irc.org/">
          <dl className="col">
            <dt>
              <h3>
                <strong className="c3">이노첸티 리서치 센터</strong>
              </h3>
            </dt>
            <dd>이탈리아 피렌체</dd>
          </dl>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="https://www.unicef.org/lac">
          <dl className="col">
            <dt>
              <h3>
                <strong className="c4">라틴아메리카/카리브해 지역사무소</strong>
              </h3>
            </dt>
            <dd>파나마 파나마시티</dd>
          </dl>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="https://www.unicef.org/eap/">
          <dl className="col">
            <dt>
              <h3>
                <strong className="c4">동아시아/태평양 지역사무소</strong>
              </h3>
            </dt>
            <dd>태국 방콕</dd>
          </dl>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="https://www.unicef.org/rosa/">
          <dl className="col">
            <dt>
              <h3>
                <strong className="c4">남아시아 지역사무소</strong>
              </h3>
            </dt>
            <dd>네팔 카트만두</dd>
          </dl>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="https://www.unicef.org/esa/">
          <dl className="col">
            <dt>
              <h3>
                <strong className="c4">동남아프리카 지역사무소</strong>
              </h3>
            </dt>
            <dd>케냐 나이로비</dd>
          </dl>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="https://www.unicef.org/wca/">
          <dl className="col">
            <dt>
              <h3>
                <strong className="c4">서부/중앙아프리카 지역사무소</strong>
              </h3>
            </dt>
            <dd>세네갈 다카르</dd>
          </dl>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="https://www.unicef.org/mena/">
          <dl className="col">
            <dt>
              <h3>
                <strong className="c4">중동/북아프리카 지역사무소</strong>
              </h3>
            </dt>
            <dd>요르단 암만</dd>
          </dl>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="https://www.unicef.org/eca/">
          <dl className="col">
            <dt>
              <h3>
                <strong className="c4">유럽/중앙아시아 지역사무소</strong>
              
            </h3>
            </dt>
            <dd>스위스 제네바</dd>
          </dl>
        </LinkSafe>
      </li>
    </UlFlex>
  </Content>
);

export default WorkTab2;
